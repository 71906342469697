import React from 'react'

import icons from './images/wetherIcons.png'

export default function Wether() {
  return (
    <div className='Wether flex flex-wrap items-center justify-center gap-y-10 gap-x-[2vw] pt-[50px]'>
        <div className='flex flex-col max-w-[670px] gap-y-2 p-[20px]'>
            <div className='title text-fontPrimary'>
                <p className='text-[7.5vmin]'>Warunki atmosferyczne</p>
                <p className='text-[7.5vmin]'>to nie problem.</p>
            </div>
            <div>
                <p className='classicFont text-[19px] font-[450]'>Odporne na zmienne warunki atmosferyczne, 
                skutecznie zabezpieczając Twoje rośliny przed ekstremalnymi 
                temperaturami czy opadami deszczu.</p>
            </div>
        </div>
        <div className='p-[10px]'>
            <img className='w-full max-w-[250px]' src={icons} alt='img'/>
        </div>
    </div>
  )
}
