import userEvent from '@testing-library/user-event';
import React, { useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import Logo from '../Logo.png'

export default function MenuDesktop() {
    
    const location = useLocation();
    const homeButtonAction = () => {
        if(location.pathname === '/'){
          window.scrollTo({
            top:0,
            left:0,
            behavior:"smooth"
          })
        }
    }

    const navRef = useRef();
    const changeNavColor = () => {
        if(window.scrollY > 100){
            navRef.current.classList.add('active');
        } else navRef.current.classList.remove('active');
    };
    
    useEffect(()=> {
        window.addEventListener('scroll', changeNavColor)
        window.addEventListener('load', changeNavColor)
        
        return () => {
            window.removeEventListener('scroll', changeNavColor)
            window.removeEventListener('load', changeNavColor)
        }
    
    },[])

    return (
        <nav className='NavDesktop flex items-center gap-x-10 fixed pl-[2%] pt-[13px] z-[3] w-full' ref={navRef}>
            <NavLink activeclassname="selected" to='/'>
                <img src={Logo} alt='img' className='w-[30px]' onClick={homeButtonAction}/>
            </NavLink>
            <ul className='classicFont flex text-[17px] gap-x-10'>
                <NavLink to="/ceny" activeclassname="selected">
                    <li>Ceny</li>
                </NavLink>
                <NavLink to="/realizacje" activeclassname="selected">
                    <li>Realizacje</li>
                </NavLink>
                <NavLink to="/kontakt" activeclassname="selected">
                    <li>Kontakt</li>
                </NavLink>
            </ul>
        </nav>
    )
}
