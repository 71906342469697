import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCards } from 'swiper/modules';

import 'swiper/css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import product1 from '../images/product1.jpeg'
import product2 from '../images/product2.jpeg'
import product3 from '../images/product3.jpeg'
import product4 from '../images/product4.jpeg'
import product5 from '../images/product5.jpeg'

import Slide from "./Slide";

export default function Slider(){
    return(
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectCards]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            loop={true}
            autoplay={{ delay: 3000 }}
        >
            <SwiperSlide>
                <Slide img={product1} size='50 x 50 x 52' cost='240zł'/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide img={product2} size='100 x 30 x 52' cost='420zł'/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide img={product3} size='50 x 50' cost='360zł'/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide img={product4} size='45 x 60' cost='360zł'/>
            </SwiperSlide>
            <SwiperSlide>
                <Slide img={product5} size='40 x 40 x 70' cost='390zł'/>
            </SwiperSlide>
        </Swiper>
    )
}