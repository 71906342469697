import React,{useEffect} from 'react'

export default function Realizations() {
  
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth",
    })
  },[])

  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };
  
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));  

  return (
    <div className='Realizations flex flex-col items-center min-h-screen pt-[100px] p-[20px] bg-primaryBg'>
        <div className='max-w-[1300px]'>
            <p className='title text-[6vmin]'>Ręcznie tworzone z najwyższej jakości materiałów.</p>
        </div>
        <div className='classicFont flex w-full max-w-[1000px] mt-5'>
            <p className='max-w-[600px]'>
                Zapewniają trwałość, elegancję i wyjątkowy design. 
                Dzięki temu każda donica jest nie tylko funkcjonalnym przedmiotem, 
                ale także wyjątkowym dziełem sztuki, które z dumą możesz eksponować w swoim domu czy ogrodzie.
            </p>
        </div>
        <div className='flex flex-wrap justify-center max-w-[1100px] gap-x-5  gap-y-4 mt-10'>
          {Object.keys(images).map((image, index) => (
            <img
              key={index}
              src={images[image]} 
              alt={`Image ${index}`} 
              className='max-w-[350px] object-cover'
            />
          ))}
        </div>
    </div>
    
  )
}
