import React, {useRef} from 'react'
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';

export default function MenuPhoneOption({isShowedMenu, handleClick}) {
    
    const ref = useRef(null);
  
    return (
        <CSSTransition
            in={isShowedMenu}
            nodeRef={ref}
            timeout={500}
            classNames={'active'}
            unmountOnExit
        >
            <div 
                className='MenuPhoneOption fixed flex items-center justify-center w-screen h-screen bg-[white] z-[3]'
                ref={ref}
            >
                <ul className='flex flex-col gap-y-4 text-[7vmin] text-fontPrimary classicFont'>
                    <NavLink to="/ceny" activeclassname="selected" onClick={handleClick}>
                        <li>wycena</li>
                    </NavLink>
                    <NavLink to="/realizacje" activeclassname="selected" onClick={handleClick}>
                        <li>realizacje</li>
                    </NavLink>
                    <NavLink to="/kontakt" activeclassname="selected" onClick={handleClick}>
                        <li>kontakt</li>
                    </NavLink>
                </ul>
            </div>
        </CSSTransition>
       
    )
}
