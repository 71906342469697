import React, { Fragment, useEffect } from "react";
import Slider from "./Products/Slider";

import Sizes from './Sizes.png'

export default function Prices(){
    
    useEffect(()=>{
        window.scrollTo({
          top:0,
          behavior:"smooth",
        })
      },[])
    
    
    return(
        <div className="min-h-screen bg-primaryBg">
            <div className="Prices flex flex-col items-center justify-center pt-[100px]">
                <div className="title Text p-[30px]">
                    <p className="text-[20px] font-[500] pb-5">
                        Odkryj niezwykłą gamę donic, 
                        które urozmaicą Twoje wnętrza i 
                        przestrzenie zielone!
                    </p>
                    <p className="text-[17px] font-[500] classicFont">                    
                    * Po indywidualną wycenę prosimy o bezpośredni kontakt
                    </p>
                </div>
                <div className="Swiper-container">
                    <div>
                        <p className="classicFont text-center">Przykładowe ceny</p>
                    </div>
                    <Slider/>
                </div>
            </div>
            <div className="flex justify-center pt-10">
                <p className="title text-[6vmin]">Jak zamówić donicę?</p>
            </div>
            <div className="Info-Container flex flex-col items-center gap-y-10 classicFont pb-40 ">
                <div  className="Info pl-5 pr-5 pt-5 max-w-[750px]">
                    <ol className="flex flex-col gap-y-3 text-[20x] mb-4">
                        <li>1. Określ długość, szerokość oraz wysokość pożądanej donicy. 
                        Wymiary podajemy w kolejności: długość, szerokość, wysokość.
                        </li>
                        <li>2. Wybierz kolor oraz strukturę.</li>
                        <li>3. Skontaktuj się z nami w celu wyceny.</li>
                        <li>4. Wycienimy Twoją donicę w ciągu 48h.</li>
                    </ol>
                    <p>* Przed rozpoczęciem prac wymagana będzie zaliczka do 30%.</p>
                </div>
                <img src={Sizes} className="w-4/5 max-w-[400px]" alt="img"/>
            </div>
        </div>
    )
}