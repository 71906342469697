import React from 'react'

import colors from './images/colors.png'

export default function Colors() {
  return (
    <div className='Colors flex flex-col items-center justify-center gap-x-10 gap-y-5 pt-[70px] text-fontPrimary'>
        <div>
          <p className='title text-[9vmin]'>Szeroka paleta barw.</p>
          <p className='classicFont text-[20px]'>Kolor spersonalizowany pod Ciebie.</p>
        </div>

        <img src={colors} className='w-[50%] max-w-[300px]' alt='img'/>
    </div>
  )
}

