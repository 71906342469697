import React from 'react'

import useCases from './images/useCases.png'

import temp from './images/temp.png'
import water from './images/water.png'
import struct from './images/struct.png'

export default function UseCases() {
  return (
    <div className='UseCases flex flex-col items-center justify-center mt-[100px] pl-2 pr-2 gap-y-10 pb-20'>
      <div className='flex flex-col gap-y-[10px] classicFont text-[17px] font-[500]'>
        <div className='flex items-center gap-x-2'>
          <p>spersonalizowane wykończenie</p>
          <img src={struct} alt='img' className='w-[65px]'/>
        </div>
        <div className='flex items-center gap-x-2'>
          <p>właściwości termoizolacyjne</p>
          <img src={temp} alt='img' className='w-[40px]'/>
        </div>
        <div className='flex items-center gap-x-2'>
          <p>odpływ wody</p>
          <img src={water} alt='img' className='w-[40px]'/>
        </div>
      </div>
      <img src={useCases} alt='img' className='w-4/5 max-w-[520px]'/>
    </div>
  )
}
