import React, { Fragment, useEffect } from 'react'

import Navigation from '../Navigation/Navigation'

export default function Contact() {
    useEffect(()=>{
        window.scrollTo({
          top:0,
          behavior:"smooth",
        })
      },[])
  
return (
    <Fragment>
        <div className='Contact flex flex-col items-center  min-h-screen gap-y-10 p-5 classicFont pt-[150px]'>
            <div className='max-w-[500px]'>
                <p className='pb-2 text-[19px] font-[500]'>Twoje pytania, uwagi czy sugestie są dla nas niezwykle ważne, ponieważ pomagają nam stale doskonalić nasze usługi.</p>
                <p>Skontaktuj się z nami, a my postaramy się jak najszybciej odpowiedzieć na Twoją wiadomość.</p>
            </div>
            <div className='flex flex-col items-center justify-center w-full contactBorder text-[20px] pt-10 pb-10'>
                <div className='pb-10 min-w-[187px] text-fontPrimary'>
                    <p>nr telefonu</p>
                    <p className='font-[500]'>607 580 659</p>
                </div>
                <div className='text-fontPrimary'>
                    <p>e-mail</p>
                    <p className='font-[500]'>kontakt@cdsolution.pl</p>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
