import React from 'react'

import landscape from './images/landscape.png'


export default function Details() {
  
    return (
    <section className='HomePageDetails classicFont flex flex-col items-center gap-y-10 pt-[70px]'>
        <div className='w-4/5 max-w-[1100px]'>
          <p className='text-[4.5vmin] font-[400]'>
            Odkryj nasze wyjątkowe termodonice, które rewolucjonizują sposób, w jaki prezentujesz 
            <span className='text-[#64A876]'> swoje rośliny!</span>
          </p>
        </div>
        <div className='w-full'>
          <img src={landscape} className='w-full h-full object-cover md:max-h-[360px] lg:max-h-[460px] xl:max-h-[560px]'/>
        </div>
    </section>
  )
}
