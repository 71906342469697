import React, { Fragment } from 'react'
import mainImage from './images/mainImage.png'

export default function Header() {
  return (
    <Fragment>
      <main className="HomePageHeader flex flex-col justify-end w-full h-svh min-h-[265px] relative title">
        <div className="TextContainer flex flex-col gap-y-3 w-full h-2/5 bg-primaryBg text-fontPrimary p-8 z-[2]">
          <p 
            className='text-[8vmin] font-[500]'>
            Elegancja dla Twoich roślin.
          </p>
          <p className='text-[4vmin] font-[400] max-w-[800px]'>
            Witajcie w świecie doniczek, 
            gdzie odkryjecie stylowe formy i kolory, 
            które ożywią Wasze rośliny! 
          </p>
        </div>
        <div className='ImgContainer h-full w-full absolute b-0'>
          <img src={mainImage} className='w-full h-full object-cover' alt='img'/>
        </div>
      </main>
    </Fragment> 
  )
}

