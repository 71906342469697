import React, { Fragment, useEffect } from 'react'

import Header from './Header'
import Details from './Details'
import Wether from './Wether'
import Colors from './Colors'
import UseCases from './UseCases'


export default function HomePage() {
  
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth",
    })
  },[])
  
  
  return (
    
    <Fragment>
      <Header/>
      <Details/>
      <Wether/>
      <Colors/>
      <UseCases/>
    </Fragment>
  )
}
