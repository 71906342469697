import React, { Fragment, useState } from 'react'
import MenuPhoneOption from './MenuPhoneOption'

import Logo from '../Logo.png'
import { NavLink, useLocation } from 'react-router-dom'

export default function MenuPhone() {
  
  const [isShowedMenu, setIsShowedMenu] = useState(false)

  const location = useLocation()
  
  const homeButtonAction = () => {
    if(location.pathname === '/'){
      window.scrollTo({
        top:0,
        left:0,
        behavior:"smooth"
      })
    }
    setIsShowedMenu(false)
  }


  return (
    <Fragment>
      <MenuPhoneOption isShowedMenu={isShowedMenu} handleClick={()=> setIsShowedMenu(!isShowedMenu)}/>
      <nav className='Menu-Phone flex justify-end fixed w-full z-[3] bg-[#ffffff36]'>
        <NavLink activeclassname="selected" to='/'>
          <img 
            src={Logo} 
            alt='img' 
            className='absolute left-5 top-3 w-[50px]' 
            onClick={homeButtonAction}
          />
        </NavLink>
        <div 
          className={`flex items-center justify-center relative w-[70px] h-[70px]`}
          onClick={()=> setIsShowedMenu(!isShowedMenu)}
        >
          <span className={`bg-[transparent] absolute w-[80%] h-[7px] ${!isShowedMenu ? 'hideMenu' : 'showedMenu'}`}></span>
        </div>
      </nav>
    </Fragment>
  )
}
