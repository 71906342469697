import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import Navigation from './components/Navigation/Navigation';
import Contact from './components/Contact/Contact';
import Prices from './components/Prices/Prices';
import Realizations from './components/Realizations/Realizations';

function App() {
  return (
    <div className="App overflow-x-hidden">
      <Navigation/>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path='/kontakt' element={<Contact/>}/>
        <Route path='/ceny' element={<Prices/>}/>
        <Route path='/realizacje' element={<Realizations/>}/>
      </Routes>
    </div>
  );
}

export default App;
