import React, { Fragment } from "react";

export default function Slide({img, size, cost}){
    return(
        <Fragment>
            <img className="w-full h-full object-cover" src={img}/>
            <div className="flex items-center justify-center gap-x-10 absolute bottom-0 w-full bg-[#efefefe0] pt-5 pb-5 classicFont md:text-[25px]">
                <div>
                    <p className="text-[17px] md:text-[25px] mb-1">wymiar</p>
                    <p className="text-[20px] md:text-[28px]">{size}</p>
                </div>
                <div>
                    <p className="text-[17px] md:text-[25px] mb-1">cena</p>
                    <p className="text-[20px] md:text-[28px]">{cost}</p>
                </div>
            </div>
        </Fragment>
    )
}