import React, { useState, useEffect, Fragment } from 'react'
import MenuPhone from './MenuPhone/MenuPhone';
import MenuDesktop from './MenuDesktop/MenuDesktop';


export default function Navigation() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(()=> {
    window.addEventListener('resize', handleResize)
    
  
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <Fragment>
      {windowWidth < 960 ? (
        <MenuPhone/>
      ) : (
        <MenuDesktop/>
      )}
    </Fragment>
  )
}
